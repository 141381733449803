const colors = {
  background: "#f1f1f1",
  bannerInfo: "#226db8",
  bannerSuccess: "#3d853d",
  bannerWarning: "#c2473d",
  black: "#000000",
  blendBackground: "rgba(255,255,255,0.15)",
  blue: "#0000ff",
  brightBlue: "#0000ee",
  brightRed: "#ed1c24",
  brown: "#4e3629",
  dangerForeground: "#ffffff",
  darkGray: "#485054",
  default: "#000000",
  divider: "#e4e4e4",
  editShortcutBackground: "#505050",
  generalText: "#2d2d2d",
  gold: "#ffc72c",
  gray: "#404040",
  green: "#008672",
  headerBackground: "#2d2d2d",
  headerText: "#e4e4e4",
  icon: "#000000",
  lightGray: "#ced1e0",
  lighterGray: "#f0f1f7",
  listBackground: "#fcfcfc",
  listSubtitle: "#737373",
  menuDividerBackground: "#f0f1f7",
  modalBackground: "rgba(52, 52, 52, 0.5)",
  muted: "#808080",
  navy: "#003c71",
  pink: "#e77c75",
  primary: "#687089",
  red: "#b73831",
  roleDepartment: "#505a5a",
  roleFaculty: "#9351a6",
  roleGrad: "#3C8243",
  roleMed: "#007da3",
  roleStaff: "#745c4f",
  roleUndergrad: "#a0652e",
  scrollbar: "#000000",
  selectedNav: "#008672",
  statusDanger: "#e20f00",
  statusMedium: "#ffc72c",
  statusSuccess: "#038742",
  subHeaderBackground: "#ffffff",
  subTitle: "#737373",
  tabDisabled: "#808080",
  tileBackground: "#ffffff",
  trueRed: "#ff0000",
  webPink: "#ffc0cb",
  welcomeTag: "#e20f00",
  white: "#ffffff"
};

export default colors;
